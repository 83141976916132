import React from 'react'
import styled from 'styled-components'
import AllCommunities from './components/AllCommunities'
import CommunitiesOver15000 from './components/CommunitiesOver15000'
import HeaderField from '../HeaderField'
import Link from '../../../../components/Link'
import InfoField from '../InfoField'

const Wrapper = styled.div``

const StyledAllCommunities = styled(AllCommunities)`
  margin-top: 20px;
`
const CenterDiv = styled.div`
  text-align: center;
`
const StyledLink = styled(Link)`
  cursor: pointer;
  margin-bottom: 20px;
  text-align: center;
`
const SuperTitle = styled.div`
  font-size: 30px;
  font-family: ${(props) => props.theme.font.primary};
  padding: 20px;
  background-color: rgb(240, 240, 240, 1);
  font-weight: bold;
  margin: unset;

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: 25px;
    text-align: center;
  }
`
const showInfo2 = () => {
  let currentTime = new Date().getTime()
  return currentTime >= 1600758000000
}

const AllMayors = ({ data, location, ...props }) => (
  <Wrapper {...props}>
    <HeaderField location={location}></HeaderField>

    <SuperTitle>Bürgermeister</SuperTitle>
    {!showInfo2() && <InfoField />}
    <CommunitiesOver15000 data={data.communitiesOver15000}></CommunitiesOver15000>
    <StyledAllCommunities data={data.allCommunities}></StyledAllCommunities>
    <CenterDiv>
      <StyledLink
        onClick={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }}
      >
        Zurück zum Anfang der Seite
      </StyledLink>
    </CenterDiv>
  </Wrapper>
)
AllMayors.propTypes = {
  allCommunities: AllCommunities.propTypes,
  communitiesOver15000: CommunitiesOver15000.propTypes,
}
AllMayors.defaultProps = {
  data: {
    allCommunities: AllCommunities.defaultProps.data,
    communitiesOver15000: CommunitiesOver15000.defaultProps.data,
  },
}
export default AllMayors
