import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import MayorChartWithoutImage from './components/MayorChartWithoutImage'
import MayorWithoutImage from './components/MayorChart/components/MayorWithoutImage'
import RowLocation from '../../../../../Weather/components/LiveData/components/RowLocation'
import AllCom from '../../../../../../config/data/southTyrolCommunities.json'
import Scrollbars from '../../../../../../components/Scrollbars'

const StyledScrollbar = styled(Scrollbars)``
const options = []

AllCom.all.forEach(function (com) {
  if (
    com.istat == 21008 ||
    com.istat == 21011 ||
    com.istat == 21040 ||
    com.istat == 21059 ||
    com.istat == 21016 ||
    com.istat == 21086 ||
    com.istat == 21051
  ) {
  } else {
    let option = { value: com.istat - 21000, label: com.name }
    options.push(option)
  }
})

const Wrapper = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`

const StyledRowLocation = styled(RowLocation)`
  width: 300px;
  margin-left: auto;
  margin-bottom: 10px;

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-right: auto;
  }
`

const FlexDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const SuperTitle = styled.p`
  font-size: 20px;
  font-family: ${(props) => props.theme.font.primary};

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    text-align: center;
  }
`

const StyledMayorChartWithout = styled(MayorChartWithoutImage)`
  height: 250px;
  max-height: 250px;

  width: 30%;

  padding: 10px;

  margin-top: 7px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;

  background-color: ${(props) => props.background};

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: 40%;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: 90%;
  }
`

const MayorPlaceDiv = styled.div`
  display: contents;
`

const AllCommunities = ({ data, ...props }) => {
  const [stateData, setStateData] = useState(data)

  function onUpdateCommunity(selectedStation) {
    let placeHoldArray = []

    stateData &&
      stateData.array.map((station) => {
        if (station.istat == selectedStation.value) {
          placeHoldArray.unshift(station)
        } else {
          placeHoldArray.push(station)
        }
      })
    setStateData({ array: placeHoldArray })
  }

  return (
    <Wrapper {...props}>
      <SuperTitle>Alle Gemeinden</SuperTitle>
      <StyledRowLocation
        values={options}
        placeholder={'select_communities'}
        onChange={onUpdateCommunity}
      ></StyledRowLocation>

      <FlexDiv>
        {stateData &&
          stateData.array.map((charts) => {
            return (
              <MayorPlaceDiv>
                <StyledMayorChartWithout finished={charts.finished === 1} data={charts}></StyledMayorChartWithout>
              </MayorPlaceDiv>
            )
          })}
      </FlexDiv>
    </Wrapper>
  )
}
AllCommunities.propTypes = {
  data: PropTypes.arrayOf(MayorChartWithoutImage.PropTypes),
}
AllCommunities.defaultProps = {
  data: {
    array: [
      {
        community: 'Keine Daten vorhanden',
        mayors: [
          MayorWithoutImage.defaultProps.data,
          MayorWithoutImage.defaultProps.data,
          MayorWithoutImage.defaultProps.data,
        ],
        sections: 0,
        sections_max: '?',
      },
    ],
  },
}
export default AllCommunities
