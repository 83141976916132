import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import MayorChart from '../AllCommunities/components/MayorChart'

const Wrapper = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`
const FlexDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const SuperTitle = styled.div`
  font-size: 20px;
  font-family: ${(props) => props.theme.font.primary};

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    text-align: center;
  }
  margin-top: 20px;
  margin-bottom: 20px;
`
const StyledMayorChart = styled(MayorChart)`
  width: 40%;

  padding: 10px;

  margin-top: 7px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;

  border: 1px solid rgb(220, 220, 220, 0.75);
  background-color: ${(props) => props.background};

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: 40%;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: 90%;
  }
`
const MayorPlaceDiv = styled.div`
  display: contents;
`

const CommunitiesOver15000 = function ({ data, ...props }) {
  return (
    <Wrapper {...props}>
      <SuperTitle>Gemeinden über 15.000 Einwohner</SuperTitle>
      <FlexDiv>
        {data.map((charts) => {
          return (
            <MayorPlaceDiv>
              <StyledMayorChart finished={charts.finished === 1} data={charts}></StyledMayorChart>
            </MayorPlaceDiv>
          )
        })}
      </FlexDiv>
    </Wrapper>
  )
}
CommunitiesOver15000.propTypes = {
  data: PropTypes.arrayOf(MayorChart.PropTypes),
}
CommunitiesOver15000.defaultProps = {
  data: [MayorChart.defaultProps.data, MayorChart.defaultProps.data, MayorChart.defaultProps.data],
}
export default CommunitiesOver15000
