import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import MayorWithoutImage from '../MayorChart/components/MayorWithoutImage'
import Scrollbars from 'react-scrollbars-custom'

import Text from '../../../../../../../../components/Text'
import MayorChartHeader from '../MayorChartHeader'
import NoDataField from '../../../../../ElectionResults/components/NoDataField'
import showInfo from '../../../../../../modules/show-info'

const StyledMayorList = styled.div`
  padding-bottom: 1px;
  padding-top: 5px;
`

const Wrapper = styled.div`
  border: 1px solid rgb(220, 220, 220, 0.75);
  height: 100%;
`

const Line = styled.hr`
  display: block;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
  color: rgb(235, 235, 235, 1);
`

const LinkSections = styled(Text)`
  margin-top: 8px;
  text-align: center;
`

const Link = styled.p`
  font-family: ${(props) => props.theme.font.primary};
  text-align: center;
  text-decoration: unset;
`

const AWrapper = styled.a`
  font-family: ${(props) => props.theme.font.primary};
  font-size: 0.8em;
  text-align: center;
  text-decoration: unset;

  color: #d41d24;

  margin-top: 5px;

  &hover {
    color: #d41d24;
  }
  &active {
    color: #d41d24;
  }
  &visited {
    color: inherit;
  }
`

const StyledMayorChartHeader = styled(MayorChartHeader)``

const StyledMayorWithout = styled(MayorWithoutImage)`
  padding-left: 0.2rem;
  padding-right: 0.2rem;
`

const StyledScrollbars = styled(Scrollbars)`
  height: calc(100% - 2.9em) !important;
  background-color: ${(props) => props.theme.color.background};
`

const MayorChartWithoutImage = ({ data, finished, ...props }) => {
  if (!showInfo()) {
    finished = false
  }
  return (
    <div {...props}>
      <Wrapper>
        <StyledMayorChartHeader community={data.community} finished={finished} />
        {
          <StyledScrollbars>
            <StyledMayorList>
              {data.mayors.map((mayor) => {
                return (
                  <div>
                    <StyledMayorWithout data={mayor} sections={data.sections}></StyledMayorWithout>
                    <Line />
                  </div>
                )
              })}

              {finished && (
                <LinkSections>
                  <b>
                    {' '}
                    {showInfo() ? data.sections : 0} von {data.sections_max} Sektionen ausgezählt
                  </b>
                </LinkSections>
              )}

              {!finished && (
                <LinkSections>
                  {showInfo() ? data.sections : 0} von {data.sections_max} Sektionen ausgezählt
                </LinkSections>
              )}
              <AWrapper href={'/p/gemeinderatswahlen-einegemeinde?gemeinde=' + data.istat}>
                <Link>Alle Daten von {data.community} &gt;&gt;</Link>
              </AWrapper>
            </StyledMayorList>
          </StyledScrollbars>
        }
      </Wrapper>
    </div>
  )
}
MayorChartWithoutImage.propTypes = {
  data: PropTypes.shape({
    community: PropTypes.string,
    istat: PropTypes.number,
    mayors: PropTypes.arrayOf(
      PropTypes.shape({
        mayor: MayorWithoutImage.propTypes,
      }),
    ),
    sections: PropTypes.number,
    sections_max: PropTypes.number,
    finished: PropTypes.number,
  }),
}
MayorChartWithoutImage.defaultProps = {
  data: {
    community: 'Keine Daten vorhanden',
    istat: '8',
    mayors: [
      MayorWithoutImage.defaultProps.data,
      MayorWithoutImage.defaultProps.data,
      MayorWithoutImage.defaultProps.data,
    ],
    sections: 0,
    sections_max: '?',
    finished: 0,
  },
}
export default MayorChartWithoutImage
