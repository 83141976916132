import React from 'react'
import styled from 'styled-components'
import ContainerResults from '../ContainerResults'

const Wrapper = styled(ContainerResults)`
  width: 100%;
  text-align: center;
  padding-top: 4em;
  padding-bottom: 4em;
  font-family: ${(props) => props.theme.font.primary};
  font-size: 1.5em;
`

const NoDataField = ({ ...props }) => <Wrapper {...props}>Noch keine Daten vorhanden</Wrapper>
NoDataField.propTypes = {}
NoDataField.defaultProps = {}
export default NoDataField
