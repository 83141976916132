import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import Icon from '../../../../../../../../components/Icon'

const Title = styled.p`
  margin-top: 10px;
  text-weight: bold;
  font-size: 20px;
  color: ${(props) => props.color};
  margin-top: auto;

  overflow: hidden;
  text-overflow: ellipsis;

  margin-bottom: auto;
  font-family: ${(props) => props.theme.font.primary};
`

const TitleDiv = styled.div`
  background-color: rgb(225, 225, 225, 1);
  position: relative;
  display: block;
  box-shadow: 0px 2px 3px 0px rgba(140, 140, 140, 0.35);
  white-space: nowrap;

  padding: 0.7rem;

  ${(props) =>
    props.finished &&
    css`
      background-color: ${(props) => props.theme.color.stol};
      background-image: url('https://s3-italy.brennercom.it/stol-images/img/speciale/gemeinderatswahlen/redfield.png');
      color: #fff;
      padding-right: 2.9rem;
    `}
`

const CheckIcon = (props) => <Icon icon="check" {...props} />

const StyledCheckIcon = styled(CheckIcon)`
  font-size: 1.5rem;
  color: white;
  position: absolute;
  right: 0.7rem;
  top: 50%;
  transform: translateY(-50%);
`

const MayorChartHeader = function ({ community, finished, ...props }) {
  return (
    <TitleDiv finished={finished} {...props}>
      {' '}
      <Title>{community}</Title>
      {finished && <StyledCheckIcon />}
    </TitleDiv>
  )
}
MayorChartHeader.propTypes = {
  community: PropTypes.string,
  finished: PropTypes.bool,
}
MayorChartHeader.defaultProps = {
  community: 'Keine Daten vorhanden',
}
export default MayorChartHeader
