import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Mayor from './components/Mayor'

import Text from '../../../../../../../../components/Text'
import Percentage from './components/Mayor/components/Percentage'
import MayorChartHeader from '../MayorChartHeader'
import NoDataField from '../../../../../ElectionResults/components/NoDataField'
import showInfo from '../../../../../../modules/show-info'

const GDiv = styled.div`
  display: flex;
  margin-bottom: 13px;
  margin-top: 5px;
`

const StyledMayor = styled(Mayor)`
  margin-left: auto;
  margin-right: auto;
`

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.color.background};
`

const Link = styled.p`
  font-family: ${(props) => props.theme.font.primary};
  text-align: center;
`

const AWrapper = styled.a`
  font-family: ${(props) => props.theme.font.primary};
  margin-top: 5px;
  font-size: 0.8em;
  text-align: center;
  text-decoration: unset;
  color: #d41d24;

  &hover {
    color: #d41d24;
  }
  &active {
    color: #d41d24;
  }
  &visited {
    color: inherit;
  }
`

const Line = styled.hr`
  display: block;
  width: 90%;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
  color: rgb(235, 235, 235, 0.6);
`

const StyledNoDataField = styled(NoDataField)`
  margin: unset;
  width: unset;
`

const LinkSections = styled(Text)`
  margin-top: 8px;
  text-align: center;
`

const StyledMayorChartHeader = styled(MayorChartHeader)`
  margin: -10px;
  margin-bottom: 3px;
`

const MayorChart = function ({ data, finished, ...props }) {
  let showNumbers = false
  if (data.sections !== undefined && data.sections > 0) {
    showNumbers = true
  }
  if (!showInfo()) {
    finished = false
  }
  return (
    <Wrapper {...props}>
      <StyledMayorChartHeader community={data.community} finished={finished} />

      {
        <div>
          <GDiv>
            {data.mayors.map((mayor) => {
              return (
                <StyledMayor
                  name={mayor.name}
                  code={mayor.code}
                  votes={mayor.votes}
                  showNumber={showNumbers}
                ></StyledMayor>
              )
            })}
          </GDiv>

          <Line />

          <LinkSections style={{ fontWeight: finished ? 'bold' : 'inherit' }}>
            {showInfo() ? data.sections : 0} von {data.sections_max} Sektionen ausgezählt
          </LinkSections>

          <AWrapper href={'/p/gemeinderatswahlen-einegemeinde?gemeinde=' + data.istat}>
            <Link>Alle Daten von {data.community} &gt;&gt;</Link>
          </AWrapper>
        </div>
      }
    </Wrapper>
  )
}
MayorChart.propTypes = {
  data: PropTypes.shape({
    community: PropTypes.string,
    mayors: PropTypes.arrayOf(Mayor.propTypes),
    sections: PropTypes.number,
    sections_max: PropTypes.number,
  }),
}
MayorChart.defaultProps = {
  data: {
    community: 'Keine Daten vorhanden',
    istat: '8',
    mayors: [
      {
        mayor: {
          name: '?',
          code: '?',
          votes: Percentage.defaultProps,
        },
      },
    ],
    sections: 0,
    sections_max: '?',
  },
}
export default MayorChart
