import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Percentage from './components/Percentage'
import ImageMapMayor from './images'
import ImageFallback from '../../../../../../../../../../components/ImageFallback'

const Wrapper = styled.div``

const Name = styled.div`
  font-family: ${(props) => props.theme.font.primary};
  font-weight: bold;
  text-align: center;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const ImageContainer = styled.div`
  width: 85px;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5em;
`

const GoodDiv = styled.div`
  display: block;
  width: 85px;
`

const Mayor = ({ name, code, votes, showNumber, ...props }) => {
  return (
    <Wrapper {...props}>
      <GoodDiv>
        <Name>{name}</Name>
        <ImageContainer>
          <ImageFallback src={ImageMapMayor(code)} fallback={ImageMapMayor(null)} />
        </ImageContainer>
      </GoodDiv>
      <Percentage data={votes} showNumbers={showNumber}></Percentage>
    </Wrapper>
  )
}
Mayor.propTypes = {
  name: PropTypes.string,
  code: PropTypes.string,
  votes: Percentage.propTypes,
}
Mayor.defaultProps = {
  name: 'Keine Daten vorhanden',
  code: 'default',
}
export default Mayor
