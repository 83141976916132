import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from '../../../../../../../../../../../../components/Text'
import LinearProgress from '@mui/material/LinearProgress'
import showInfo from '../../../../../../../../../../modules/show-info'

const Wrapper = styled.div`
  width: 100%;
`
const Percent = styled(Text)`
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`
const Percentage = ({ data, showNumbers, ...props }) => (
  <Wrapper {...props}>
    {showInfo() && showNumbers && <Percent>{data.percentage}%</Percent>}
    <LinearProgress variant="determinate" value={showInfo() ? data.percentage : '0%'} color="secondary" />
  </Wrapper>
)
Percentage.propTypes = {
  data: PropTypes.shape({
    percentage: PropTypes.number,
  }),
}
Percentage.defaultProps = {
  data: {
    percentage: 0,
  },
}
export default Percentage
