import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Percentage from '../Mayor/components/Percentage'
import Text from '../../../../../../../../../../components/Text'
import showInfo from '../../../../../../../../modules/show-info'

const Wrapper = styled.div`
  display: flex;
`
const Name = styled(Text)`
  font-weight: bold;
`

const Number = styled(Text)`
  margin-left: auto;
`

const MayorWithoutImage = ({ data, sections, ...props }) => (
  <Wrapper {...props}>
    <Name>{data.name}</Name>
    {showInfo() && sections !== undefined && sections > 0 && <Number>{data.votes.percentage}%</Number>}
  </Wrapper>
)
MayorWithoutImage.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    votes: Percentage.propTypes,
  }),
}
MayorWithoutImage.defaultProps = {
  data: {
    name: 'Keine Daten vorhanden',
    votes: Percentage.defaultProps,
  },
}
export default MayorWithoutImage
